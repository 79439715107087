import React, { Component } from 'react';
import PlayerAPI, { PlayerError } from 'API/player';
import Loader from './loader';

class MediaPlayer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            error: null
        };

        this.mounted = true;
    }

    embedVideo() {
        // TODO: Subscribe to login state changes and recreate player
        PlayerAPI.embedPlayer('video-player-' + this.props.entryId, this.props.entryId)
            .then(() => {
                if (!this.mounted) {
                    return;
                }

                this.setState({ loading: false });
            })
            .catch(error => {
                console.error(error);
                if (!this.mounted) {
                    return;
                }

                if (error instanceof PlayerError) {
                    this.setState({ loading: false, error: error.message });
                    return;
                }

                console.log(error);
                this.setState({ loading: false, error: 'An unknown error occurred. Please try again later.' });
            });
    }

    destroyVideo(entryId) {
        if (!entryId) {
            entryId = this.props.entryId;
        }
        PlayerAPI.destroy('video-player-' + entryId);
    }

    componentDidMount() {
        this.embedVideo();
    }

    componentWillUnmount() {
        this.destroyVideo();
        this.mounted = false;
    }

    getSnapshotBeforeUpdate(prevProps) {
        if (prevProps.entryId !== this.props.entryId) {
            this.destroyVideo(prevProps.entryId);
            return true;
        }
        return false;
    }

    componentDidUpdate(_prevProps, _prevState, didDestroy) {
        if (didDestroy) {
            this.embedVideo();
        }
    }

    render() {
        return (
            <div className="c-media-player">
                <div className="c-media-player__ratio-lock">
                    <Loader className="c-media-player__loader" loading={this.state.loading}>
                        {this.state.error ? (
                            <div className="c-media-player__error">
                                <p className="c-media-player__error-message">{this.state.error}</p>
                            </div>
                        ) : (
                            <React.Fragment>
                                <div className="c-media-player__video" id={'video-player-' + this.props.entryId}></div>
                            </React.Fragment>
                        )}
                    </Loader>
                </div>
                {this.state.error || (!this.props.live && !this.props.footnote) ? null : (
                    <div className="c-media-player__extras">
                        {this.props.live ? (
                            <div className="c-on-air">
                                <span className="c-on-air__lightbulb"></span>
                                <span className="c-on-air__text">Live Now</span>
                            </div>
                        ) : null}
                        {this.props.footnote ? (
                            <p className="c-media-player__subtitle">
                                <small>{this.props.footnote}</small>
                            </p>
                        ) : null}
                        <span className="c-media-player__spacer"></span>
                    </div>
                )}
            </div>
        );
    }
}

MediaPlayer.defaultProps = {
    skipKs: false,
    ks: '',
    drm: false
};

export default MediaPlayer;
